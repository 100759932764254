// ########################## [IMPORTANT LIBRARIES]
import { useMemo } from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [TYPES]
import { Order } from '@web/types/order';
import { ErrorCategory } from '@web/types/order/error';

// ########################## [PAGE COMPONENTS]
import ErrorCategoryBadge from '@web/features/ship/tabs/Error/components/table/ErrorCategoryBadge';

// ########################## [HOOKS]
import useGetTabColumnSize from '@web/features/ship/hooks/tabs/useGetTabColumnSize';

const columnHelper = createColumnHelper<Order>();

export const ErrorTypeColumnCode = 'errorType';

export const useGetErrorTypeColumn = (
  ignoreRowClick = false,
): ColumnDef<Order, ErrorCategory | null> => {
  const { t } = useTranslation(),
    transErrorTable = translations.ship.table,
    transError = transErrorTable.columns;

  const size = useGetTabColumnSize(ErrorTypeColumnCode, 150);

  return useMemo(
    () =>
      columnHelper.accessor('error_category', {
        header: t(transError.errorType),
        minSize: 100,
        size,
        maxSize: 150,
        meta: {
          ignoreRowClick: ignoreRowClick,
        },
        id: 'error-type',
        enableSorting: false,
        cell: ({ row: { original: order } }) =>
          errorTypeColumnRendererFn(order),
      }),
    [t, transError, size, ignoreRowClick],
  );
};

const errorTypeColumnRendererFn = (order: Order) => {
  const target = `error-status-${order.id}`;

  return (
    <ErrorCategoryBadge
      target={target}
      allowEvents
      errCategoryString={order.error_category ?? ErrorCategory.unknown}
    />
  );
};
