import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { CarrierErrorMessageSuggestionWithLinks } from '@web/features/ship/types';

import {
  Button,
  PopoverBody,
  PopoverHeader,
} from '@shippypro/design-system-web';
import { Color, StyleType } from '@shippypro/design-system-web/enums';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';

import { TextWithCustomHover } from '@web/components/common-ui/tables/columns';

export interface IWhatCanIDoProps {
  suggestion: CarrierErrorMessageSuggestionWithLinks;
  renderLabelAsButton?: boolean;
}

/**
 * This component holds a link that opens a popover on click
 * The popover shows info regarding a possible solution for an order error
 * and a link (if present) to the related help article
 *
 * @interface IWhatCanIDoProps
 *
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 */
export const WhatCanIDo: FC<IWhatCanIDoProps> = ({
  suggestion,
  renderLabelAsButton = false,
}) => {
  const { t } = useTranslation(),
    transSuggestions = translations.ship.carrierErrorSuggestions;

  const readMoreLinks = suggestion.links;

  return (
    <TextWithCustomHover
      label={
        renderLabelAsButton ? (
          <Button styleType={StyleType.filled} color={Color.light}>
            <span className="flex items-center content-center !gap-1">
              <IconHelper icon="IconBulbFilled" size={18} />
              <span className="font-bold text-primary underline hover:underline">
                {t(transSuggestions.proposedActionTitle)}
              </span>
            </span>
          </Button>
        ) : (
          <span className="flex">
            <IconHelper className="ml-1" icon="IconBulbFilled" size={17} />
            {t(transSuggestions.proposedActionTitle)}
          </span>
        )
      }
      labelProps={{
        className: 'font-bold text-primary underline hover:underline',
        id: `carrier-error-suggestion-cta`,
      }}
      popperClassName="!max-w-[300px]"
      target={`error-suggestion-popover`}
      type="popover"
    >
      <PopoverHeader className="error-suggestion-popover-header">
        <div className="d-flex justify-content-between">
          <span className="d-flex flex-row">{t(transSuggestions.title)}</span>
          {readMoreLinks && readMoreLinks.length > 0 && (
            <Button
              styleType={StyleType.flat}
              style={{ borderRadius: 0 }}
              onClick={e => {
                e.stopPropagation();
                readMoreLinks.forEach(link => window.open(link, '_blank'));
              }}
              className="error-suggestion-help-link d-flex flex-row text-md cursor-pointer p-0 text-white hover:underline"
            >
              {t(transSuggestions.link)}
            </Button>
          )}
        </div>
      </PopoverHeader>
      <PopoverBody className="error-suggestion-popover-body max-h-72 overflow-y-auto shadow-down">
        <div>
          <div className="font-bold">
            {t(transSuggestions.descriptionTitle)}
          </div>
          <div>{suggestion.description}</div>
        </div>
        <div>
          <div className="font-bold mt-1">
            {t(transSuggestions.proposedActionTitle)}
          </div>
          <Trans
            i18nKey={suggestion.proposedAction}
            components={{
              statuspageanchor: (
                <p
                  className="text-black underline hover:underline hover:text-inherit cursor-pointer inline-flex !gap-1"
                  data-test="status-page-link-anchor"
                  onClick={() =>
                    window.open('https://status.shippypro.com', '_blank')
                  }
                />
              ),
            }}
          />
        </div>
      </PopoverBody>
    </TextWithCustomHover>
  );
};
