// ########################## [WHY DID YOU RENDER]
/// <reference types="@welldone-software/why-did-you-render" />
import '@web/utils/@wdyr/wdyr';

// ########################## [POLYFILLS]
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// ########################## [IMPORTANT LIBRARIES]
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

// ########################## [IMPORTED STYLES]
import 'sanitize.css/sanitize.css';
import 'react-intl-tel-input/dist/main.css';
import '@shippypro/design-system-web/styles';
import 'assets/styles/tailwind/index.scss';
import 'assets/styles/custom/index.scss';

// ########################## [INITIALISE LANGUAGES]
import '@shippypro/translations/init';

// ########################## [INITIALISERS]
import { initSentry } from './utils/@sentry';
import { store } from '@web/utils/@reduxjs/configureStore';

// ########################## [FALLBACKS]
import FatalErrorPage from './features/500';

// ########################## [APP PROPER]
import { App } from '@web/features';

initSentry();

const root = createRoot(document.getElementById('root') as HTMLElement);

(async () => {
  require('dotenv').config();

  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.NX_LAUNCHDARKLY_CLIENTID || 'CLIENTID_NOT_FOUND',
    context: {
      kind: 'user',
      key: '0',
      name: 'Anonymous User',
      email: 'anonymous@shippypro.com',
    },
    options: { disableSyncEventPost: true },
  });

  root.render(
    <LDProvider>
      <Provider store={store}>
        <HelmetProvider>
          <BrowserRouter>
            <ErrorBoundary FallbackComponent={FatalErrorPage}>
              <App />
            </ErrorBoundary>
          </BrowserRouter>
        </HelmetProvider>
      </Provider>
    </LDProvider>,
  );
})();

// Hot reloadable translation json files
if (module.hot) {
  /* istanbul ignore next */
  module.hot.accept(['@shippypro/translations/init'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}
