import { FC } from 'react';
import classnames from 'classnames';

import { TooltipWrapperByID } from '@ds-web/components/molecules/ui/tooltips/tooltip-wrapper-byid/TooltipWrapperByID';
import { TooltipWrapperByREF } from '@ds-web/components/molecules/ui/tooltips/tooltip-wrapper-byref/TooltipWrapperByREF';
import { IconHelper } from '@ds-web-iconhelper';

import { IInteractablePillProps } from './types';
import { StyledInteractablePill } from '@ds-web/components/molecules/ui/pills/interactable-pill/styles';

/**
 * An interactable pill that can have:
 * - An image
 * - A label
 * - A hover state
 * - An action
 * - A grey OR inverted white background
 * - A customisable className
 * - A right icon with
 *   - An action
 *   - A hover state
 *   - A customisable className
 *
 * @interface InteractablePill
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const InteractablePill: FC<IInteractablePillProps> = ({
  id,
  imgUrl,
  label,
  onClick,
  textOnHover,
  checkOverflow = true,
  whiteBG,
  className,
  isSelected,
  isHighlighted,
  rightBtn,
  isInteractible,
  onRemoveSelectionBtnClick,
  ...props
}) => {
  const parsedId = `${id.replace(/[^a-zA-Z0-9]/g, '')}`;

  return (
    <StyledInteractablePill
      {...props}
      id={`pill-${parsedId}`}
      className={classnames(
        // --- COMPONENT STYLES ---
        'interactable-pill', // Common identifier
        'cursor-pointer', // Interactions
        'w-auto max-w-[210px] min-w-[35px] h-[35px] p-[2px]', // Dimensions
        'flex justify-between items-center !gap-2', // Flex layout
        'border !border-[color:--shp-color-bg-highlight] hover:!border-[color:--shp-color-genericborder-light] rounded-full', // Border
        'transition-colors hover:!bg-[color:--shp-color-bg-hover]', // Colors
        // --- CONDITIONAL STYLES ---
        {
          'has-label': label, // Padding right only IF the label is present
          '!bg-white !border-[color:--shp-color-genericborder-light] has-white-bg':
            whiteBG, // Inverted white background
          '!w-fit': !label, // Width fit only IF the label is NOT present
          'is-selected': isSelected,
          'is-not-selected': !isSelected,
          'is-highlighted': isHighlighted,
          'pr-2': Boolean(rightBtn),
        },
        // --- CUSTOM STYLES ---
        className,
      )}
    >
      {/* [IMG + LABEL SECTION] ----------------------------------------- */}
      {/* Tooltip Wrapper - only visible if there's text on hover to show */}
      <div
        className={classnames(
          'h-[-webkit-fill-available] img-label-block flex items-center truncate cursor-default',
          {
            'cursor-pointer': (onClick && !isSelected) || isInteractible,
          },
        )}
        onClick={!isSelected ? onClick : undefined}
      >
        {imgUrl && (
          <TooltipWrapperByID
            target={`targetImg_${parsedId}`}
            text={textOnHover}
            active={!label && !!textOnHover}
            checkOverflow={false}
          >
            <img
              src={imgUrl}
              alt="pill img"
              className="interactable-pill-img flex-[1_0_auto] w-[2.125rem] h-8 bg-white rounded-full"
              loading="lazy"
            />
          </TooltipWrapperByID>
        )}
        {label && (
          <TooltipWrapperByID
            target={`targetLbl_${parsedId}`}
            text={textOnHover}
            active={!!textOnHover}
            checkOverflow={checkOverflow}
            avoidScrollHeightCheck
          >
            <div
              className={classnames(
                'interactable-pill-label flex-[0_1_auto] font-semibold truncate text-base text-[color:--shp-color-text-body]',
                { 'cursor-pointer ': !isSelected },
              )}
            >
              {label}
            </div>
          </TooltipWrapperByID>
        )}
      </div>
      {/* [RIGHT ICON SECTION] ------------------------------------------ */}
      {/* Tooltip Wrapper - only visible if there's text on hover to show */}
      {rightBtn && (
        <TooltipWrapperByREF
          containerProps={{
            className: classnames(
              'right-btn-block',
              'flex justify-end flex-[1_0_auto]',
              '!p-[0.125rem]',
              {
                '!bg-[--shp-color-bg-hover] !text-[--shp-color-bg-dark-highlight] !rounded-full':
                  isSelected,
              },
            ),
          }}
          content={rightBtn.textOnHover}
          disabled={!rightBtn.textOnHover}
          fullWidth={false}
        >
          <IconHelper
            icon={rightBtn.icon}
            size={14}
            onClick={rightBtn.onClick}
            id={rightBtn.id}
            className={classnames(
              'transition-transform ease-in-out duration-150',
              rightBtn.className,
              {
                'cursor-pointer': rightBtn.onClick,
                'rotate-180': rightBtn.rotated ?? false,
              },
            )}
          />
        </TooltipWrapperByREF>
      )}
      {/* Remove selection */}
      {isSelected && onRemoveSelectionBtnClick && (
        <span
          className="!bg-[--shp-color-bg-hover] !rounded-full !p-[0.125rem]"
          id="remove-filter-btn"
        >
          <IconHelper
            icon="IconX"
            size={14}
            onClick={onRemoveSelectionBtnClick}
            className="text-[--shp-color-bg-dark-highlight]"
          />
        </span>
      )}
    </StyledInteractablePill>
  );
};
