import { Dispatch, FC, memo, SetStateAction, useRef } from 'react';
import classnames from 'classnames';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { Button, Input } from '@shippypro/design-system-web';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';

interface ISearchInputProps {
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;

  setIsOpen: Dispatch<SetStateAction<boolean>>;

  setShowSearchSuggestion: Dispatch<SetStateAction<boolean>>;

  onFetch: (query: string) => void;

  wasQueryFired: boolean;
}

/**
 * **[COMPONENT]** The search-input component.
 * The input that manages the text value for the Global Search.
 *
 * @interface ISearchInputProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const SearchInput: FC<ISearchInputProps> = memo(
  ({
    query,
    setQuery,
    setIsOpen,
    setShowSearchSuggestion,
    onFetch,
    wasQueryFired,
  }) => {
    const { t } = useTranslation(),
      trans = translations,
      transSearch = trans.globalSearch,
      transCommon = trans.common.form;

    const queryInvalid = query.length < 3 || query.length > 256;
    const queryValid = query.length > 2 && query.length < 255;

    const inputRef = useRef<HTMLInputElement>(null);

    const onEscape = () => {
      setIsOpen(false);
      setShowSearchSuggestion(false);
      inputRef.current?.blur();
      setQuery('');
    };

    const onSearchInputFocus = () => {
      if (wasQueryFired || (!wasQueryFired && !query.length)) {
        setShowSearchSuggestion(true);
      }
      setIsOpen(true);
    };

    const onSearchInputChange = e => {
      setQuery(e.target.value);
      if (!e.target.value.length) {
        setShowSearchSuggestion(true);
        setIsOpen(true);
      }
    };

    const onSearchInputClear = () => {
      setQuery('');
      setShowSearchSuggestion(true);
      setIsOpen(true);
      inputRef.current?.focus();
    };

    return (
      <>
        <div className="w-full flex items-center !gap-2">
          <IconHelper
            icon="IconSearch"
            size={20}
            className="shrink-0 text-primary"
          />
          <Input
            id="search-input"
            innerRef={inputRef}
            className="w-[98%] !border-none !shadow-none !rounded-none !p-0"
            placeholder={t(transSearch.search)}
            value={query}
            onFocus={onSearchInputFocus}
            onChange={onSearchInputChange}
            onKeyDown={e => {
              if (e.key === 'Enter' && queryValid) {
                onFetch(query);
                setIsOpen(true);
                setShowSearchSuggestion(false);
              } else if (e.key === 'Escape') {
                onEscape();
              }
            }}
          />
        </div>
        <div
          className={classnames(
            'shrink-0 flex items-center !gap-2',
            'transition-opacity duration-150',
            {
              'opacity-0 !cursor-text': queryInvalid,
              'opacity-100': queryValid,
            },
          )}
          onClick={() => queryInvalid && inputRef.current?.focus()}
        >
          <IconHelper
            icon="IconX"
            size={18}
            className={classnames('shrink-0 text-purpleish !mr-2', {
              'cursor-text': queryInvalid,
              'cursor-pointer': queryValid,
            })}
            onClick={onSearchInputClear}
          />
          <Button
            id="search-btn"
            className={classnames(
              'shrink-0 !py-2 !px-4',
              '!rounded-full !bg-primary !text-white focus:!bg-primary focus:!text-white',
            )}
            onClick={() => {
              inputRef.current?.focus();
              onFetch(query);
              setShowSearchSuggestion(false);
              setIsOpen(true);
            }}
            disabled={!query.length}
          >
            {t(transCommon.search)}
          </Button>
        </div>
      </>
    );
  },
);

SearchInput.displayName = 'SearchInput_memoized';

export default SearchInput;
