import styled from 'styled-components';
import { Row } from 'reactstrap';

const StyledContainer = styled(Row)<{
  $fullHeight: boolean;
  $unpadCards: boolean;
}>`
  height: ${props => (props.$fullHeight ? `calc(100% - 10rem)` : ``)};
  width: 100%;

  padding: 0 !important;
  margin: 0 !important;

  .card-top-tabs-container {
    position: relative;

    height: 100%;

    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-bottom: 0.5rem !important;
  }

  // ---------------------[ TITLE CUSTOMISATION ]
  .card-with-top-tabs-title {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 1.5rem;
    color: var(--shp-color-genericui-primary);
  }

  // ---------------------[ NAVS CUSTOMISATION ]
  .card-top-tabs {
    background-color: white !important;

    border-top: 1px solid var(--shp-color-genericborder-input);
    border-left: 1px solid var(--shp-color-genericborder-input);
    border-right: 1px solid var(--shp-color-genericborder-input);
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;

    padding-bottom: 0.75rem;

    .nav-tabs {
      margin-bottom: 0;

      padding-top: 0.5rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      .nav-item:not(.nav-item-dropdown):not(.nav-item-dropdown-button),
      .btn-group {
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;

        padding: 0.25rem 1.7rem;

        border-radius: 0px 6px 6px 0px;

        transition: background-color 0.1s ease-in-out;

        background-color: transparent !important;

        &:has(.active) {
          .nav-link.active {
            font-weight: 700 !important;
          }
          .active-nav-link {
            font-weight: 700 !important;
            padding: 0 !important;
            color: var(--shp-color-genericui-primary);
          }
        }
      }

      .nav-item-dropdown-button {
        .nav-link {
          padding: 0 !important;
          margin-right: 0.75rem !important;
        }
      }

      .nav-link {
        margin-right: 0;
        font-weight: 500 !important;
        color: var(--shp-color-text-tab-title);

        & + .badge {
          margin: 0;

          &.bg-none {
            padding: 0;

            background-color: transparent !important;
            color: var(--shp-color-genericui-primary);

            font-size: 0.893rem;
            letter-spacing: 0.13px;
          }

          &.bg-danger {
            color: #8e1f0b !important;
            background-color: #fed3d1 !important;
          }

          &:not(.bg-none) {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;
            padding: 0.35rem;
            border-radius: 500px;
          }
        }
        ::after {
          background: none !important;
          transform: none !important;
        }
      }
    }
  }

  .dropdown-menu .dropdown-item:focus .nav-item,
  .dropdown-menu .dropdown-item:active .nav-item,
  .dropdown-menu .dropdown-item:focus a,
  .dropdown-menu .dropdown-item:active a,
  .dropdown-menu .dropdown-item:focus span,
  .dropdown-menu .dropdown-item:active span {
    color: white !important;
  }

  .content-card {
    position: relative;

    border-radius: 0;
    box-shadow: none !important;

    overflow: hidden;

    border-bottom: 1px solid var(--shp-color-genericborder-input);
    border-left: 1px solid var(--shp-color-genericborder-input);
    border-right: 1px solid var(--shp-color-genericborder-input);
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;

    &:not(.no-card) {
      margin: 0 !important;
    }

    .tab-pane-card {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  .animated-active-marker {
    display: flex;

    transition: all 0.1s ease-in-out;

    height: 45px;
    background-color: var(--shp-color-bg-highlight);

    border-radius: 6px;

    bottom: 0px;
    position: absolute;
  }

  // ---------------------[ CARD CUSTOMISATION ]
  .card-body {
    padding: ${props => (props.$unpadCards ? `0 !important` : ``)};
  }

  // ---------------------[ CONTENT CUSTOMISATION ]
  .select__control--is-disabled,
  .form-control:disabled,
  .input-group--disabled {
    background-color: #efefef !important;
  }

  div:has(> table.react-table) {
    height: 100% !important;
  }

  table.react-table {
    margin-bottom: 2rem !important;
  }

  .react-table-pagination {
    position: absolute;
    bottom: 0 !important;

    width: 100% !important;

    padding: 0.25rem 1rem !important;

    .prev-item,
    .page-item,
    .next-item {
      background-color: transparent !important;
    }

    .prev-item button,
    .page-item:not(.active) button,
    .next-item button {
      background-color: transparent !important;

      &:hover {
        background-color: transparent !important;
        color: var(--shp-color-genericui-primary) !important;
      }
    }

    .page-item.active button {
      border-radius: 9999px !important;
      background-color: var(--shp-color-bg-highlight) !important;
      color: var(--shp-color-genericui-primary) !important;

      width: 1rem !important;
      height: 2rem !important;
      min-width: 2rem !important;
    }

    .page-item button,
    #scroll-to-table-top {
      background-color: transparent !important;
      color: var(--bs-pagination-color) !important;

      &:hover {
        color: var(--shp-color-genericui-primary) !important;
      }

      overflow: hidden;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      height: 2rem !important;
      width: 2rem !important;
      padding: 0.25rem !important;
    }

    .page-item button {
      span {
        display: inline-flex;
        justify-content: center;

        svg {
          width: 20px !important;
          height: 20px !important;
        }
      }
    }
  }
`;

export default StyledContainer;
