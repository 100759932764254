import { PropsWithChildren, ReactElement } from 'react';

export enum unitPositionEnum {
  RIGHT = 'right',
  LEFT = 'left',
}

export interface IKpiDataProps extends PropsWithChildren {
  code: string;
  kpiUnit: string;
  unitPosition?: unitPositionEnum;
  kpiValue: string | 0;
  kpiPreviousValue?: number | 0;
  kpiTrendId: string;
  isError?: boolean;
  error?: string;
  kpiTrend?: number;
  invertTrendColor?: boolean;
  isFetching?: boolean;
  trendPopover?: (value: number, previousValue: number) => ReactElement;
}
